@font-face {
    font-family: 'OpenSansCondensed';
    src: url('../../fonts/OpenSansCondensed-Light.ttf');
    font-weight: lighter;
}
@font-face {
    font-family: 'OpenSansCondensed';
    src: url('../../fonts/OpenSansCondensed-Bold.ttf');
    font-weight: bold;
}
