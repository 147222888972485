.ckan-profile {
  #content {
    background-color: $profile-bg;
    padding-bottom: 90px;
  }
}
.profile-banner {
  background: url('../../images/banner.jpeg') 50% 50% no-repeat;
  background-size: cover;
  margin: 0 -15px 55px;
  padding-top: 220px;
}
.profile-actions {
  min-height: 80px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 14px 80px 8px;
  a {
    font-weight: 400;
    color: $profile-blue;
    &:hover {
      color: #e33d0e;
    }
  }
  .portal-title {
    font-size: 28px;
    font-weight: 400;
    line-height: 1;
    color: #132b3a;
    margin: 0 0 5px;
  }
  .portal-tlink {
    font-size: 16px;
    font-weight: 300;
  }
  .actions {
    text-align: right;
    padding-top: 4px;
    & > div {
      display: inline-block;
    }
  }
  .btn {
    width: 150px;
    height: 40px;
    background-color: $ds-green;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
    box-shadow: none;
    padding: 10px;
    margin: 0;
    margin-left: 10px;
    margin-bottom: 10px;
    &:hover,
    &:focus {
      color: #fff;
      background-color: darken($ds-green, 0.2);
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
        0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
  }
  .title {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #132b3a;
    padding-right: 25px;
    margin-bottom: 10px;
    strong {
      font-weight: 600;
    }
  }
}
.banner-footer {
  min-height: 100px;
  background-color: #fff;
  box-shadow: 0px 2px 15px 0px rgba(116, 131, 138, 0.35);
  padding: 20px 80px 4px;
  .counters {
    padding-left: 0;
    li {
      width: 120px;
      display: inline-block;
      list-style: none;
      border: 1px solid #898989;
      border-right: none;
      text-align: center;
      padding: 6px 0;
      p {
        font-size: 15px;
        line-height: 1.2;
        font-weight: 400;
        color: #777;
        margin-bottom: 0;
      }
      span {
        font-size: 24px;
        font-weight: 500;
        color: $profile-blue;
      }
      &:last-child {
        border-right: 1px solid #898989;
      }
    }
  }
}
.portal-logo {
  width: 136px;
  height: 136px;
  background-color: #fff;
  border: 1px solid #132b3a;
  border-radius: 50%;
  text-align: center;
  padding: 15px;
  margin-top: -82px;
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
.portal-text {
  font-size: 14px;
  line-height: 20px;
  color: rgb(119, 119, 119);
  padding-left: 155px;
}
.portal-title-block {
  padding-left: 170px;
}

.content-tabs {
  .nav-tabs {
    flex-direction: column;
    position: absolute;
    width: 100px;
    height: 100%;
    background-color: $profile-blue;
    li {
      height: 100px;
      position: relative;
      z-index: 1;
      &.active,
      &:hover {
        a {
          font-weight: 600;
          text-decoration: none;
        }
        &:before {
          transform: scaleX(1);
        }
        &:after {
          right: -37px;
          transform: scaleX(1);
        }
        .tab-icon {
          transform: scale(1.2);
        }
      }
      a {
        display: block;
        height: 100%;
        font-family: 'OpenSansCondensed';
        font-size: 14px;
        font-weight: 300;
        line-height: 1;
        color: #fff;
        text-align: center;
        padding: 24px 5px;
      }
      &:after {
        content: '';
        z-index: -1;
        position: absolute;
        width: 0;
        height: 0;
        right: 100px;
        top: 0;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        border-left: 37px solid #17a2b8;
        transform: scaleX(0);
        transition-property: right, border-color;
        transition-duration: 0.4s;
        transition-timing-function: linear;
      }
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #17a2b8;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition-property: transform;
        transition-duration: 0.3s;
        transition-timing-function: linear;
      }
    }
    .tab-icon {
      display: block;
      width: 36px;
      height: 25px;
      background-image: url('../../images/sprite.png');
      background-size: 325%;
      background-repeat: no-repeat;
      margin: 0 auto 13px;
      transition-duration: 0.3s;
      transition-property: transform;
      transition-timing-function: ease-out;
      &.overview {
        background-position: -33px -41px;
        height: 20px;
      }
      &.deployment {
        background-position: -100px -31px;
        background-size: 380%;
      }
      &.statistic {
        background-position: 1px -41px;
      }
      &.tags {
        background-position: -98px 0px;
        height: 28px;
        background-size: 350%;
      }
    }
  }
}
.content-tabs-body {
  background-color: #fff;
  &.card-body {
    min-height: 450px;
    padding: 65px 215px 125px 315px;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
  }
  h4 {
    font-size: 16pt;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .chart {
    border: 1px solid rgb(165, 168, 170);
    padding: 20px;
    margin-bottom: 64px;
  }
}

.content-body {
  display: flex;
  padding: 0 80px;
}
.profile-content {
  flex: 4.6;
  width: 60%;
  position: relative;
  overflow: hidden;
}
.profile-sidebar {
  flex: 1;
  width: 40%;
  .navbar {
    width: 213px;
    box-shadow: none;
    padding: 0;
    .navbar-nav {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
    }
    .nav-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100px;
      height: 100px;
      background-color: transparent;
      border-radius: 10px;
      font-family: 'OpenSansCondensed';
      font-size: 14px;
      font-weight: 700;
      color: #4e606b;
      text-align: center;
      margin-bottom: 13px;
      i {
        font-size: 30px;
        margin-bottom: 5px;
      }
      &.active {
        background-color: #67b845;
        box-shadow: 7px 7px 12px 0px rgba(69, 81, 79, 0.35);
        color: #fff;
      }
    }
  }
}
