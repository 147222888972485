$roboto-font-path: '~mdbreact/dist/font/roboto/' !default;
$image-path: '~mdbreact/dist/img' !default;

// Fonts
$font-family-base: 'Open Sans', sans-serif;

// Setting custom gradient.
$gradients: () !default;
$gradients: map-merge(
  (
    'ds-green': (
      'start': #67b845,
      'end': #078094,
    ),
  ),
  $gradients
);

// Primary and secondary colors.
$primary-color: #132b3a;
$primary-green: #67ba00;
$secondary-color: #777777;
$profile-blue: #078094;
$profile-bg: #ecf1f4;
$ds-green: #64b647;
$background-gradient: linear-gradient(258deg, #cee1ea 0%, #eae9d7 100%);
// Forms.
$input-border-color: $primary-color;

// Footer colors.
$footer-top-bg-color: #fff;
$footer-copyright-color: $secondary-color !default;
$footer-copyright-bg-color: #f7f7f7;
$footer-font-size: 0.9rem !default;
