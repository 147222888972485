#root {
  background: $background-gradient;
}
body {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300;
  color: $primary-color;
  font-size: 0.75rem;

  @include media-breakpoint-up(md) {
    & {
      font-size: 1rem;
    }
  }
}

table {
  &.table {
    td {
      word-break: break-all;
      a {
        &:hover {
          color: $secondary-color;
        }
      }
    }
  }
}

.subtitle {
  font-size: 18px;
}

.btn {
  border-radius: 5px;
  font-size: 1em;
  &.btn-sm {
    font-size: 0.75em;
  }
  &.btn-md {
    font-size: 1em;
  }
  &.btn-rounded {
    border-radius: 2em;
  }
}

.search-form {
  box-sizing: content-box;
  z-index: 10;
  display: flex;
  flex-direction: row;
  flex: 1;
  .form-inline {
    flex-flow: row;
  }
  #portal-search {
    display: flex;

    flex: 1;
    .search-input {
      display: flex;
      flex: 1;
      align-items: center;
    }
    .form-group {
      flex: 1;
      margin-bottom: 0;
      .form-control {
        flex: 1;
        width: 100%;
        &::placeholder {
          color: #4f5d66;
        }
      }
    }
  }
  .form-control {
    padding: 0 45px;
    border: 1px solid $ds-green;
    border-radius: 10px;
  }

  .search-icon,
  .search-clear {
    position: relative;
  }

  .search-icon {
    left: 35px;
  }

  .search-clear {
    cursor: pointer;
    left: -35px;
  }

  .tooltip {
    bottom: 0px;
    transform: translate(10%, 95%);
    z-index: 5;
    .arrow {
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
      &:before {
        bottom: -1px;
      }
    }
  }

  .spinner-border {
    vertical-align: middle;
  }
}
.actions-element {
  position: relative;
  left: -10px;

  .btn-search {
    font-size: 16px;
    font-weight: 700;
    padding: 0 25px;
    text-transform: none;
    transition: all 0.5s ease;

    border: 2px solid $ds-green;
    border-radius: 10px;
    background-color: transparent;
    height: calc(1.5em + 0.75rem + 2px);

    color: $ds-green;

    display: inline-flex;
    align-items: center;

    &:hover:enabled {
      background-color: $ds-green;
      color: $white;
    }
  }
}
.btn.btn-default.btn-rounded.ds-green-gradient {
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $primary-green;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:hover {
    &:before {
      transform: scaleX(1);
    }
  }
}

header.app-header {
  background-color: transparent;
  .header-container {
    padding: 0 152px;
    background-color: $white;
    box-shadow: 0px 6px 16px rgba(19, 43, 58, 0.06);
    .btn {
      background-color: $ds-green;
    }
  }

  .subheader {
    display: flex;
    justify-content: center;

    .banner-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 0;
      transition: all 0.8s ease;

      .banner-text-title {
        color: $ds-green;
        max-width: 618px;
        font-size: 36px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: center;
      }

      .banner-text-content {
        max-width: 530px;
        font-size: 17px;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .btn-pair {
      display: inline-block;
      background-color: #fff;
      border: 1px solid $ds-green;
      border-radius: 10px;

      .switch-view-btn {
        margin: 3px;
        padding: 0 5px;
        border-radius: 8px;
        background-color: #fff;
        color: black;
        box-shadow: none;
        text-transform: none;

        &:first-of-type {
          margin-right: 0;
        }
        &.active {
          background-color: $ds-green;
          color: white;
        }
        &:hover {
          background-color: $ds-green;
          color: white;
          transition: all ease 0.3s;
        }
      }
    }
  }
  .navbar-main {
    margin-right: 0 !important;
  }
  .navbar-auth {
    .btn {
      white-space: nowrap;
    }
    margin-left: 0 !important;
  }
  nav {
    height: 110px;
    padding: 0;
    .navbar-brand {
      img {
        width: 170px;
        @include media-breakpoint-up(sm) {
          width: 200px;
        }
      }
    }
    .navbar-nav {
      height: 100%;
      .nav-item {
        white-space: nowrap;
        padding: 0 13px;
        display: flex;
        align-items: center;
        .nav-link {
          position: relative;
          font-size: 16px;
          color: $black !important;
          font-weight: 400;
          padding: 10px 7px;
          &.active {
            &:after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              background: #67b845;
              height: 3px;
            }
          }
        }
        @include media-breakpoint-down(md) {
          display: inline-block;
          .nav-link {
            &.active {
              &:after {
                content: none;
              }
            }
          }
        }
      }
    }
    .btn-primary {
      background-color: $ds-green !important;
      border-color: transparent;
    }
    .navbar-collapse {
      height: 100%;
      justify-content: flex-end;
    }
    @include media-breakpoint-down(md) {
      & {
        height: auto;
      }
    }
  }
}

section#content_outer_wrapper {
  background-color: transparent;
  .near-banner & {
    padding-top: 20px;
    @include media-breakpoint-up(sm) {
      padding-top: 32px;
    }
  }
  .featured-list {
    margin-top: 55px;
    margin-bottom: 60px;
    p {
      color: #132b3a;
      font-size: 17px;
      font-weight: 300;
      margin-bottom: 28px;
    }
    .view {
      border: 1px solid #a5a8aa;
    }
  }
  .page-maintenance & {
    padding-top: 100px;
    padding-bottom: 100px;
    h1 {
      margin-bottom: 1em;
    }
  }
}

.home-page-content {
  padding: 0 171px;
  h3 {
    font-weight: 400;
  }
  .btn-default {
    font-family: 'Open Sans';
    font-size: 16px;
    line-height: 45px;
    padding: 0 28px;
  }
}

footer.app-footer {
  color: $primary-color;
  padding: 0;
  .footer-container {
    padding: 0 152px;
    background-color: $white;
  }
  .footer-top {
    padding: 10px 0;
    background: $footer-top-bg-color;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .menu-brand {
      font-size: 1em;
      font-weight: 400;
      margin-right: 20px;
      img {
        max-width: 50px;
      }
    }
    .column-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
    .nav {
      &.menu-footer {
        color: $primary-color;
        a {
          color: inherit;
          padding-left: 10px;
          padding-right: 10px;
          &:hover {
            color: $primary-green;
          }
        }
      }
      &.menu-social {
        .tw_logo,
        .fb_logo,
        .gh_logo,
        .ld_logo {
          width: 22px;
          height: 22px;
          margin-right: 5px;
          background-image: url('../../images/sprite.png');
          background-size: 700%;
          background-repeat: no-repeat;
          opacity: 0.65;
          &:hover {
            opacity: 1;
          }
        }
        .tw_logo {
          background-position: 1px -87px;
        }
        .fb_logo {
          background-position: -119px -63px;
        }
        .gh_logo {
          background-position: -90px -54px;
        }
        .ld_logo {
          background-position: -22px -87px;
        }
        .nav-link {
          padding: 0;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      & {
        .menu-brand {
          margin-right: 0;
          margin-bottom: 0.5rem;
        }
        .column-inner {
          flex-direction: column;
        }
        .nav {
          width: 100%;
          &.menu-footer {
            flex-direction: column;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }
  }
  .footer-copyright {
    display: flex;
    align-items: center;
    background-color: $footer-copyright-bg-color;
    height: 40px;
    font-size: 14px;
  }
}

#ckan-global-map {
  margin-bottom: 40px;
  #global-map {
    height: 550px;
  }
}

.chart-wrapper {
  .chart-legend-wrapper {
    &.legend-center {
      & > div {
        justify-content: center !important;
      }
    }
  }
}

/* Animation */
@keyframes hvr-icon-wobble-horizontal {
  16% {
    transform: translateX(6px);
  }
  33% {
    transform: translateX(-5px);
  }
  49% {
    transform: translateX(4px);
  }
  66% {
    transform: translateX(-2px);
  }
  83% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}

.leaflet-popup-content {
  max-height: 100px;
  overflow-x: hidden;

  margin: 13px 15px 13px 0 !important;
  ol {
    padding-left: 30px;
  }
  > a {
    padding-left: 20px;
  }
}

.banner-block {
  background-position: 50%;
  .banner-heading {
    font-size: 32px;
    @include media-breakpoint-up(sm) {
      font-size: 36px;
    }
    font-family: 'Open Sans';
    font-weight: 900;
    margin-top: 120px;
    margin-bottom: 140px;
  }
}
.road-map {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .mile-stone {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    align-items: center;
    .mile-stone-sign {
      display: inline-block;
      @include media-breakpoint-up(md) {
        position: absolute;
        width: 250px;
        right: 164px;
        top: 0px;
      }
      @include media-breakpoint-up(lg) {
        width: 287px;
      }
      .sign-heading {
        min-height: 80px;
        background: #0b8094 !important;
        box-shadow: 1px 2px 4px #999;
        text-align: center;
        color: #fff;
        padding: 6px 15px;
        display: block;
        font-size: 22px;
        text-transform: none;
        width: 100%;
        cursor: default;
        margin: 0px;
        a {
          text-decoration: none;
          color: white;
        }
        @include media-breakpoint-up(sm) {
          padding: 6px 20px;
        }
        @include media-breakpoint-up(lg) {
          padding: 6px 35px;
        }
        &:before {
          content: ' ';
          display: inline-block;
          border: 3px solid white;
          border-radius: 50%;
          margin-bottom: 0.3em;
          margin-right: 0.5em;
        }
      }
      .sign-content {
        text-align: center;
        padding: 17px 17px;
        font-size: 18px;
        font-weight: 300;
        .accent {
          color: #e55931;
        }
      }
    }
    &.mile-stone--single {
      .mile-stone-sign {
        position: relative;
        margin-top: 40px;
        right: 0px;
        &:before {
          content: ' ';
          display: block;
          height: 24px;
          width: 24px;
          top: -38px;
          left: calc(50% - 11px);
          border-radius: 50%;
          position: absolute;
          background: #66b746;
        }
        .sign-heading {
          &:before {
            content: none;
          }
        }
      }
    }
    &.row-reverse {
      @include media-breakpoint-up(md) {
        .mile-stone-sign {
          left: 165px;
        }
      }
    }
    & + .mile-stone {
      $margin: 180px;
      margin-top: $margin;
      &:before {
        content: ' ';
        display: block;
        height: $margin;
        width: 6px;
        top: -$margin - 10px;
        left: calc(50% - 5px / 2);
        border-radius: 5px;
        position: absolute;
        background: linear-gradient(#088093 0%, #66b746 70%);
      }
      img:before {
        content: '-';
      }
    }
    img {
      max-width: 275px;
      @include media-breakpoint-up(md) {
        max-width: 144px;
        &.mile-stone--icon-statistics {
          max-width: 117px;
          margin-left: 13px;
          margin-right: 14px;
        }
        &.mile-stone--icon-historical {
          max-width: 133px;
          margin-left: 5px;
          margin-right: 6px;
        }
      }
      margin-bottom: 20px;
    }
  }
}

.invalid-input {
  animation: shake 1s;
}

// autocomplete window position and styling
.portal-search-autocomplete {
  z-index: 10;
  list-style: none;
  position: absolute;
  background: white;
  padding: 5px 0;
  overflow: hidden;
  max-width: 300px;

  border: 1px solid $ds-green;
  border-radius: 10px;

  top: 40px;
  left: 35px;
  z-index: 462;
  li {
    padding: 5px 15px;
    overflow: hidden;
    &:hover {
      cursor: pointer;
    }
  }
}

// user auth icon
.user-dropdown {
  .dropdown-toggle {
    &:after {
      content: none;
    }
    img {
      border: 2px solid $ds-green;
      border-radius: 10px;
      width: 40px;
    }
  }
}

// navbar toggle btn (to expand menu)
#root .navbar-toggler {
  color: #00525b;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  @include media-breakpoint-down(md) {
    .navbar-toggler-icon {
      background-color: #00525b;
      width: 25px;
      height: 25px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }
}

// paddings for contact us form
.contact-us {
  padding: 20px 0;
}
