// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
// Integrate mdbootstrap.
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~bootstrap-css-only/css/bootstrap.min.css';

@import "~mdbreact/dist/scss/core/bootstrap/functions";
@import "~mdbreact/dist/scss/core/bootstrap/variables";

// Leaflet styles.
@import "~leaflet/dist/leaflet.css";
@import "~react-leaflet-markercluster/dist/styles.min.css";

// CORE
@import "~mdbreact/dist/scss/core/mixins";
// Your custom variables
@import "main/custom-variables";
@import "~mdbreact/dist/scss/core/colors";
@import "~mdbreact/dist/scss/core/variables";
@import "~mdbreact/dist/scss/core/global";
@import "~mdbreact/dist/scss/core/helpers";
@import "~mdbreact/dist/scss/core/typography";
@import "~mdbreact/dist/scss/core/masks";
@import "~mdbreact/dist/scss/core/waves";

// FREE
@import "~mdbreact/dist/scss/free/animations-basic";
@import "~mdbreact/dist/scss/free/modules/animations-extended/module";
@import "~mdbreact/dist/scss/free/buttons";
@import "~mdbreact/dist/scss/free/cards";
@import "~mdbreact/dist/scss/free/dropdowns";
@import "~mdbreact/dist/scss/free/input-group";
@import "~mdbreact/dist/scss/free/navbars";
@import "~mdbreact/dist/scss/free/pagination";
@import "~mdbreact/dist/scss/free/badges";
@import "~mdbreact/dist/scss/free/modals";
@import "~mdbreact/dist/scss/free/carousels";
@import "~mdbreact/dist/scss/free/forms";
@import "~mdbreact/dist/scss/free/msc";
@import "~mdbreact/dist/scss/free/footers";
@import "~mdbreact/dist/scss/free/list-group";
@import "~mdbreact/dist/scss/free/tables";
@import "~mdbreact/dist/scss/free/switch";
@import "~mdbreact/dist/scss/free/depreciated";
@import "~mdbreact/dist/scss/free/steppers";
@import "~mdbreact/dist/scss/free/loader";

/* C3 charts css */
@import "~c3/c3.css";


//== Custom sass
@import 'main/custom-fonts';
@import 'main/custom-styles';
@import 'main/portal-profile';

@import 'main/custom-responsive';

@import 'main/animations';
