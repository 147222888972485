@keyframes shake {
  10%, 90% {
    transform: translateY(-1px);
  }

  20%, 80% {
    transform: translateY(2px);
  }

  30%, 50%, 70% {
    transform: translateY(-4px);
  }

  40%, 60% {
    transform: translateY(4px);
  }
}
