@media (max-width: 1400px) {
  .profile-banner {
    .sticky {
      position: unset !important;
    }
  }
}

@media (max-width: 1450px) {
  header.app-header {
    .header-container {
      padding: 0 20px;
    }
  }
  footer.app-footer {
    padding: 0;
  }

  .banner-footer {
    .counters {
      li {
        width: 88px;
        span {
          font-size: 18px;
        }
      }
    }
  }
  .profile-actions {
    .title {
      padding-right: 0;
    }
  }
}

@media (max-width: 1100px) {
  header.app-header {
    .navbar-collapse {
      margin: 0 -20px;
    }
  }
  footer.app-footer {
    .footer-container {
      padding: 0;
    }
  }

  .profile-actions,
  .banner-footer,
  .content-body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-tabs-body.card-body {
    padding: 65px 65px 125px 175px;
  }

  .home-page-content {
    padding: 0 40px;
  }
}

@media (max-width: 900px) {
  footer.app-footer {
    .footer-top {
      .column-inner {
        float: none;
      }
    }
  }

  .content-body {
    flex-direction: column;
  }
  .profile-content {
    flex: 1;
    width: 100%;
  }
  .profile-sidebar {
    flex: 1;
    width: 100%;
    .navbar {
      width: 100%;
      margin-bottom: 30px;
      ul {
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 767px) {
  header.app-header {
    .header-container {
      padding: 20px;
      .tooltip {
        transform: translate(10%, 25%);
      }
    }
  }
  .portal-link {
    font-size: 16px;
  }
  .portal-title-block {
    text-align: center;
    padding-left: 20px;
  }
  .portal-logo {
    float: none;
    margin: 0 auto 10px;
  }
  .portal-text {
    padding-left: 0;
  }
  .counters {
    float: none;
    display: block;
    width: 265px;
    margin: 15px auto 20px;
  }
  .profile-actions {
    .portal-title {
      font-size: 22px;
    }
    .actions {
      text-align: center;
    }
  }

  .content-tabs {
    .nav-tabs {
      flex-direction: row;
      position: unset;
      width: 100%;
      height: auto;
      li {
        width: 80px;
        height: 70px;
        margin-right: 16px;
        a {
          font-size: 12px;
          padding: 14px 5px;
        }
        &:after {
          border-top: 35px solid transparent;
          border-bottom: 35px solid transparent;
          border-left: 15px solid #17a2b8;
        }
        &:hover,
        &.active {
          &:after {
            right: -15px;
          }
        }
      }
    }
  }

  header.app-header {
    .banner-text {
      margin-left: 0;
    }
  }
}

@media (max-width: 439px) {
  .profile-sidebar {
    .navbar {
      width: 213px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .content-tabs {
    .nav-tabs {
      li {
        width: auto;
        margin-right: 0;
        a {
          padding: 14px 10px;
        }
        &:after {
          content: none;
        }
      }
    }
  }
  .content-tabs-body.card-body {
    padding: 20px 0;
    overflow-x: scroll;
  }

  .home-page-content {
    padding: 0 20px;
  }
}

@media (max-width: 375px) {
  .profile-actions {
    .btn {
      margin-bottom: 10px;
    }
  }
}

@include media-breakpoint-down(lg) {
}

@include media-breakpoint-down(md) {
  footer.app-footer {
    .footer-top {
      flex-direction: column;
    }
  }
}

@include media-breakpoint-down(sm) {
}
